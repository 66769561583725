<template>
    <div>
        <div class="order-detail__body">
            <div class="order-detail__details">
                <div class="detail-rows">
                    <div class="detail-row">
                        <div class="detail-name">
                            Order ID:
                        </div>
                        <div
                            class="detail-value tooltip-zoom"
                            style="cursor: pointer"
                            data-tooltip-attribute="Copy to clipboard"
                            @click="copyOrderId(order.orderid)"
                        >
                            {{ order.orderid }}
                            <img
                                src="@/assets/img/svg/clipboard-two-papers.svg"
                                alt="clipboard"
                                width="18px"
                                height="18px"
                                class="card_body__detail-img"
                                style="margin-left: 10px"
                            >
                        </div>
                    </div>
                    <div
                        v-if="order.linked_to_order"
                        class="detail-row"
                    >
                        <div class="detail-name">
                            Extra pages for order ID:
                        </div>
                        <div class="detail-value">
                            {{ order.linked_to_order }}
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-name">
                            Order date:
                        </div>
                        <div
                            v-if="order.created_at"
                            class="detail-value"
                        >
                            {{ order.created_at | moment_full_text }}
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-name">
                            Due date:
                        </div>
                        <div
                            v-if="order.due_at"
                            class="detail-value"
                        >
                            <strong>
                                {{ order.due_at | moment_full_text }}
                            </strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="order-detail__details">
                <h3
                    class="detail-title"
                >
                    Requested services
                </h3>
                <br><br>
                <!-- project type -->
                <div class="detail-row">
                    <div class="detail-name">
                        Project:
                    </div>
                    <div class="detail-value">
                        <b>{{ order.long_names.project_type }}</b>
                    </div>
                </div>
                <!-- project level -->
                <div
                    class="detail-row"
                >
                    <div class="detail-name">
                        Level:
                    </div>
                    <div class="detail-value">
                        <b>{{ order.long_names.academic_level }}</b>
                    </div>
                </div>
                <!-- citation style -->
                <div
                    class="detail-row"
                >
                    <div class="detail-name">
                        Citation Style:
                    </div>
                    <div class="detail-value">
                        {{ order.long_names.citation_style }}
                    </div>
                </div>

                <!-- subject -->
                <div
                    class="detail-row"
                >
                    <div class="detail-name">
                        Subject / Field of study:
                    </div>
                    <div class="detail-value">
                        {{ order.long_names.subject }}
                    </div>
                </div>

                <div
                    class="detail-row"
                >
                    <div class="detail-name">
                        Sources:
                    </div>
                    <div class="detail-value detail-value-services">
                        {{ order.sources }}
                    </div>
                </div>
                <div class="detail-row extra-services">
                    <template v-for="(item, index) in extraServices">
                        <div
                            v-if="item.check"
                            :key="index"
                            class="card_body__detail"
                        >
                            <div class="card_body__detail-title">
                                {{ item.title }}
                            </div>
                            <div style="display: flex">
                                <div
                                    class="card_body__detail-counter"
                                >
                                    <img
                                        src="@/assets/img/svg/check-outline.svg"
                                        alt="check"
                                    >
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="order-detail__details order-detail__details-description">
                <h3
                    class="detail-title"
                >
                    Description
                </h3>
                <div
                    v-if="order.title"
                    class="detail-row"
                >
                    <div class="detail-name">
                        Title:
                    </div>
                    <div class="detail-value">
                        <b>&laquo;{{ order.title }}&raquo;</b>
                    </div>
                </div>
                <div
                    v-if="order.details"
                    class="detail-row"
                >
                    <div class="detail-name">
                        Instructions:
                    </div>
                    <div class="detail-value">
                        <div
                            v-html="SplitByCharacters(order.details)"
                        >
                            {{ order.details }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js';
import { eventBus } from '@/helpers/event-bus'

export default {
    mixins: [filtersMixin],
    props: {
        order: {
            type: Object
        }
    },
    computed: {
        hasServices() {
            return this.order.chk_abstract || this.order.chk_outline || this.order.chk_uploads || this.order.sources
        },
        orderCardElementsSecondRow() {
            return [
                {
                    title: 'Pages',
                    count: this.order.pages,
                    isActive: this.order.pages > 0,
                    image: 'order-page.svg'
                },
                {
                    title: 'Slides',
                    count: this.order.slides,
                    isActive: this.order.slides > 0,
                    image: 'order-slides.svg'
                },
                {
                    title: 'Charts',
                    count: this.order.charts,
                    isActive: this.order.charts > 0,
                    image: 'order-charts.svg'
                },
                {
                    title: 'Problems',
                    count: this.order.problems,
                    isActive: this.order.problems > 0,
                    image: 'problem.svg'
                }
            ]
        },
        extraServices() {
            return [
                {
                    title: 'Abstract',
                    check: this.order.chk_abstract
                },
                {
                    title: 'Outline',
                    check: this.order.chk_outline
                },
                {
                    title: 'Fax sources',
                    check: this.order.chk_faxes
                },
                {
                    title: 'Upload additional files',
                    check: this.order.chk_uploads
                }
            ]
        }
    },
    methods: {
        SplitByCharacters(text) {
            const splitString = text.split('\n').join('<br>')
            return splitString
        },
        copyOrderId(orderid) {
            navigator.clipboard.writeText(orderid)
            eventBus.$emit('showSnackBar', 'Copying to clipboard was successful!', 'success');
        }
    }
}
</script>

<style scoped lang="scss">

.detail-section {
    width: 70%;
    color: #181818;
    &__row {
        word-break: break-word;
        position: relative;
        margin-bottom: 15px;
    }
}

.detail-value-services {
    width: 100%;
}

.detail-title {
    font-size: 24px;
}

.detail-row {
    display: flex;
    margin-bottom: 10px;
    line-height: 1.3;
    &.extra-services {
        flex-wrap: wrap;
        .card_body__detail{
            padding-left: 0;
            padding-right: 20px;
        }
    }
}

.detail-name {
    min-width: 260px;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: $font-color-main;
}

@media all and (max-width: 480px) {
    .detail-row {
        font-size: 14px;
    }
    .detail-name {
        font-size: 14px;
        min-width: 50px;
        width: 80px;
        margin-right: 40px;
        word-break: break-word;
    }
}

</style>
