<template>
    <div>
        <order-details
            :order="order"
        />
    </div>
</template>

<script>
import OrderDetails from '@/components/account/orders/OrderDetails.vue'

export default {
    components: {
        OrderDetails
    },
    props: {
        order: {
            type: Object
        }
    }
}
</script>
